/**
 * Prevents Angular change detection from
 * running with certain Web Component callbacks
 */
// eslint-disable-next-line no-underscore-dangle
(window as any).__Zone_disable_customElements = true;

// Mejora el rendimiento y evita fallos en pantallas blancas
(window as any).__Zone_disable_requestAnimationFrame = true;
(window as any).__Zone_disable_on_property = true;
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove', 'touchmove', 'touchstart'];

// Soluciona problemas en navegadores antiguos
(window as any).global = window;
